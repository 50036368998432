.table_container {
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 8px !important;

  .table {
    background-color: #0a0d2c;
    border: 1px solid rgba(49, 52, 84, 0.5);
    color: white;
    table-layout: fixed;

    .table_row {
      .table_cell_h {
        color: rgba(121, 128, 169, 1) !important;
      }

      .table_cell {
        border: none;
        border-bottom: 1px solid rgba(49, 52, 84, 0.5);
        font-family: Lato;
        font-size: 1em;
        font-style: normal;
        font-weight: 500;
        color: white;
        padding: 0.3em 1em;
        text-align: center;
      }
    }
  }

  // Header-specific styles
  thead {
    .table_row {
      .table_cell {
        padding: 1em;
        text-align: center !important;
        &:first-child {
          max-width: 5.25em;
          width: 4em;
        }
      }
    }
  }

  // Rankings-specific styles (if applicable)
  &.rankings {
    thead {
      .table_row {
        .table_cell {
          padding: 1em;

          &:first-child {
            max-width: 5.25em;
            width: 2em;
          }
        }
      }
    }
  }

  // Add styles specifically for payouts table
  &.payouts {
    .table {
      .table_cell {
        &:first-child {
          max-width: 6em;
          width: 3em;
          white-space: nowrap; // Prevent wrapping
          // text-overflow: ellipsis; // Add ellipsis if text is too long
        }
        &:last-child {
          text-align: right; // Left-align the amount values
          white-space: nowrap; // Ensure single-line display
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .table_container {
    .table {
      background-color: #0a0d2c;
      border: 1px solid rgba(49, 52, 84, 0.5);
      color: white;
    }
    .table_row {
      .table_cell_h {
        color: rgba(121, 128, 169, 1) !important;
      }
      .table_cell {
        border: none;
        border-bottom: 1px solid rgba(49, 52, 84, 0.5);
        font-family: Lato;
        font-size: 1.2em;
        font-style: normal;
        font-weight: 500;
        color: white;
        padding: 1em 1em;
        // border-right: 1px solid rgba(49, 52, 84, 0.5);
      }
    }
    thead {
      .table_row {
        .table_cell {
          padding: 1em;
        }
      }
    }
  }
}
