.table_cell_values {
  .value1 {
    font-size: 1em;
  }
  .value2 {
    font-size: 1em;
  }
}

.radio_buttons_wrapper {
  background-color: rgba(19, 27, 74, 1);
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  .radio_option {
    color: white;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
  }
}

.header {
  display: flex;
  width: 100%;
  margin-bottom: 1.5em;

  height: fit-content;
  justify-content: space-between;
  .title {
    color: #fff;
    font-family: 'Lato';
    font-size: 1.4em;
    font-weight: 500;
    line-height: 1em;
    line-height: normal;
  }

  .header_buttons {
    display: flex;
    .dots_button {
      margin-left: 40px;
      width: 40px;
      height: 40px;
      background-color: rgba(19, 27, 74, 1);
      border-radius: 50%;
      .dots_icon {
        color: white;
      }
    }
  }
}

.account_tab_wrapper {
  // display: flex;
  flex-wrap: nowrap;
  width: 100%;
  .securities_wrapper {
    // width: 100%;
    height: 100%;
    padding: 2em;
    overflow: hidden;
  }
  .securities {
    display: flex;
    border-radius: 8px;
    background: linear-gradient(180deg, #090c2b 0%, #0e1240 100%);
    flex-direction: column;
    overflow: hidden;
    .body {
      width: 100%;
      height: 85%;
      overflow: auto;
      // display: flex;
      flex: 1;
      .chart_wrapper {
        height: 90%;
      }
      .table1 {
        width: 291px;
      }
      .chart {
        padding: 54px;
      }
    }
  }
  .header_1024 {
    display: none;
  }

  .rankings {
    // height: 100%;
    flex: 1;
    padding: 2em 0.9em 1em 0.9em;
    display: flex;
    flex-direction: column;
  }
  .rankings_wrapper {
    background: linear-gradient(180deg, #090c2b 0%, #0e1240 100%);
    // margin-left: 16px;
    text-align: center;
    display: flex;
    .transactions {
      display: flex;
      border-radius: 16px;
    }

    .header {
      justify-content: center;
      flex-direction: column;
    }
    .security_label {
      // display: flex;
      font-family: Lato;
      color: rgba(188, 193, 222, 1);
      font-size: 0.73vw;
      font-weight: 400;
      line-height: 17px;
      color: rgba(58, 53, 197, 1);
      // margin-top: 0.83vw;
      .security_number {
        font-weight: 700;
      }
    }

    .stats {
      .pie1_1024 {
        display: none;
      }
      .pie1 {
        width: 3em;
        margin-right: 1em;
        img {
          width: 100%;
        }
      }
      color: white;
      display: flex;
      flex-direction: column;
      font-family: Lato;
      font-size: 0.85em;
      font-weight: 500;
      line-height: 19px;
      text-align: end;
      .stat {
        display: flex;
        margin-bottom: 0.4vw;
        align-items: center;
        padding: 0px 1.5em;
        .stat_values {
          margin-left: auto;
          .value1 {
            font-size: 1em !important;
            line-height: 1.4em !important;
          }
          .value2 {
            font-size: 1em !important;
            line-height: 1.4em !important;
          }
        }
      }
      .total_stat {
        border-radius: 8px;
        background-color: rgba(19, 27, 74, 1);
        padding: 0.7em 25px;
      }
    }
    .simulations_remaining {
      display: flex;
      align-items: center;
      margin-top: auto;
      .chart_legend {
        display: flex;
        align-items: center;
        margin-left: 16px;
        .color {
          width: 16px;
          height: 16px;
          background-color: rgba(58, 53, 197, 1);
          border-radius: 4px;
          margin-right: 16px;
        }
        .label4 {
          font-family: Lato;
          font-size: 0.9em;
          font-weight: 400;
          line-height: 1.2em;
          color: white;
        }
      }
    }
    .circular_progress_wrapper {
      width: 80px;
      height: 80px;
      background-color: rgba(19, 27, 74, 1);
      border-radius: 50%;
      .circular_label_wrapper {
        flex-direction: column;
        .remaining {
          font-family: Inter;
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: center;
          color: white;
        }
        .progress {
          font-family: Inter;
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          letter-spacing: 0em;
          text-align: center;
          color: rgba(135, 255, 197, 0.81);
          margin-top: 10px;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 1.5vw;
      // margin-top: 32px;
      .simulate_button {
        padding: 8px 12px !important;
        background: rgba(57, 52, 195, 0) !important;
        border: 1px solid rgba(57, 52, 195, 1) !important;
        text-transform: capitalize;
        border-radius: 8px;
        font-family: Lato;
        font-size: 0.9em;
        font-weight: 500;
        line-height: normal;
        text-wrap: nowrap;
        min-width: 40%;
        width: 47%;
        transition:
          background 0.2s,
          opacity 0.2s;
        .simulate_button_icon {
          transition: filter 0.2s;
          filter: brightness(0) invert(1);
          width: 16px;
        }
        &:hover {
          background: rgba(57, 52, 195, 0.5) !important;
          .user_button_icon {
            filter: brightness(0) invert(1);
          }
        }
        &:disabled {
          background: rgba(196, 194, 255, 0.363) !important;
          color: #aa0000;
          cursor: no-drop !important;
          pointer-events: auto;
          .simulate_button_icon {
            filter: brightness(0.5) invert(1) !important;
          }
        }
      }
    }
  }
}

@media (orientation: portrait) {
  .account_tab_wrapper {
    display: flex !important;
    flex-direction: column !important;
    .header_1024 {
      display: flex;
      width: 100%;
      height: fit-content;
      justify-content: space-between;

      .header_labels {
        .title {
          color: white;
          font-size: 3.4em;
        }
        .sims {
          // margin-top: 15px;
          display: flex;
          .sims_remaining {
            color: rgba(39, 110, 192, 1);
            // font-size: 20px;
            // font-weight: 500;
            line-height: normal;
            // margin-right: 17px;
          }
          .sims_value {
            font-family: Lato;
            // font-size: 20px;
            // font-weight: 400;
            line-height: normal;
            color: rgba(40, 161, 133, 1);
            margin-left: 1em;
          }
        }
      }

      .header_buttons {
        display: flex;
        .dots_button {
          // margin-left: 40px;
          width: 40px;
          height: 40px;
          background-color: rgba(19, 27, 74, 1);
          border-radius: 50%;
          .dots_icon {
            color: white;
          }
        }
      }
    }
    .securities {
      order: 2;
      background: none;
      padding: 0;
      .header {
        display: none;
      }
      .body {
        width: 100%;
        // display: flex;
        // min-height: 445px;
        flex: 1;
        height: 40vh;

        .table1 {
          // width: 291px;
        }
        .chart {
          // padding: 54px;
        }
      }
    }

    .rankings_wrapper {
      background: none;
      // margin-left: 16px;
      text-align: center;

      padding: 0;
      .transactions {
        display: flex;
        // border-radius: 16px;
      }

      .header {
        display: none;
      }
      .security_label {
        // display: flex;
        font-family: Lato;
        color: rgba(188, 193, 222, 1);
        // font-size: 14px;
        // font-weight: 400;
        // line-height: 17px;
        .security_number {
          font-weight: 700;
        }
      }

      .stats {
        .pie1_1024 {
          display: block;
        }
        .pie1 {
          // width: 25px;
          // margin-right: 14px;
          img {
            width: 100%;
          }
        }
        min-width: 400px;
        margin-left: auto;
        margin-right: auto;
        .stat {
          // padding: 0px 25px;
          font-family: Lato;
          font-size: 1.2em;
          // font-weight: 500;
          line-height: normal;
          color: rgba(121, 128, 169, 1);
          .stat_values {
            // margin-left: unset;
            display: flex;
            flex-grow: 1;
            .value1 {
              // margin-right: 93px;
              margin-left: auto;
            }
            .value2 {
              margin-left: auto;
            }
          }
        }
        .total_stat {
          // padding: 15px;
          // margin-top: 32px;
          // margin-bottom: 32px;
        }
      }
      .simulations_remaining {
        display: flex;
        align-items: center;
        display: none;
        .chart_legend {
          display: flex;
          align-items: center;
          margin-left: 16px;
          .color {
            // width: 16px;
            // height: 16px;
            background-color: rgba(58, 53, 197, 1);
            border-radius: 4px;
            // margin-right: 16px;
          }
          .label4 {
            font-family: Lato;
            // font-size: 16px;
            // font-weight: 400;
            // line-height: 19px;
            color: white;
          }
        }
      }
      .circular_progress_wrapper {
        // width: 80px;
        // height: 80px;
        background-color: rgba(19, 27, 74, 1);
        border-radius: 50%;
        .circular_label_wrapper {
          flex-direction: column;
          .remaining {
            font-family: Inter;
            // font-size: 18px;
            // font-weight: 600;
            // line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
            color: white;
          }
          .progress {
            font-family: Inter;
            // font-size: 12px;
            // font-weight: 600;
            // line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(135, 255, 197, 0.81);
            // margin-top: 10px;
          }
        }
      }
      .buttons {
        display: flex;
        justify-content: unset;
        flex-direction: row;
        max-width: unset;
        // margin: 0;
        justify-content: space-around;
        .simulate_button {
          // padding: 8px 12px !important;
          background: rgba(57, 52, 195, 0) !important;

          border: 1px solid rgba(57, 52, 195, 1) !important;
          // width: 192px;
          &:first-child {
            // margin-right: 96px;
          }
          .simulate_button_icon {
            transition: filter 0.2s;
            filter: brightness(0) invert(1);
            // width: 16px;
          }
          &:hover {
            background: rgba(57, 52, 195, 1) !important;
            opacity: 0.6;
            .user_button_icon {
              filter: brightness(0) invert(1);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .account_tab_wrapper {
    display: flex !important;
    flex-direction: column !important;
    position: relative;
    padding-bottom: 7em;
    .header_1024 {
      display: flex;
      width: 100%;
      height: fit-content;
      justify-content: space-between;

      .header_labels {
        .title {
          color: white;
          font-size: 3.4em;
        }
        .sims {
          // margin-top: 15px;
          display: flex;
          .sims_remaining {
            color: rgba(39, 110, 192, 1);
            // font-size: 20px;
            // font-weight: 500;
            line-height: normal;
            // margin-right: 17px;
            font-size: 2em;
          }
          .sims_value {
            font-family: Lato;
            // font-size: 20px;
            // font-weight: 400;
            line-height: normal;
            color: rgba(40, 161, 133, 1);
            margin-left: 1em;
            font-size: 2em;
          }
        }
      }

      .header_buttons {
        display: flex;
        .dots_button {
          // margin-left: 40px;
          width: 40px;
          height: 40px;
          background-color: rgba(19, 27, 74, 1);
          border-radius: 50%;
          .dots_icon {
            color: white;
          }
        }
      }
    }
    .securities {
      order: 2;
      background: none;
      padding: 0;
      .header {
        display: none;
      }
      .body {
        width: 100%;
        // display: flex;
        // min-height: 445px;
        flex: 1;
        height: 40vh;

        .table1 {
          // width: 291px;
        }
        .chart {
          // padding: 54px;
        }
      }
    }

    .rankings_wrapper {
      background: none;
      // margin-left: 16px;
      text-align: center;

      padding: 0;
      .transactions {
        display: flex;
        // border-radius: 16px;
      }

      .header {
        display: none;
      }
      .security_label {
        // display: flex;
        font-family: Lato;
        color: rgba(188, 193, 222, 1);
        // font-size: 14px;
        // font-weight: 400;
        // line-height: 17px;
        .security_number {
          font-weight: 700;
        }
      }

      .stats {
        .pie1 {
          // width: 25px;
          // margin-right: 14px;
          img {
            width: 100%;
          }
        }
        // max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        .stat {
          // padding: 0px 25px;
          font-family: Lato;
          font-size: 2em;
          // font-weight: 500;
          line-height: normal;
          color: rgba(121, 128, 169, 1);
          margin: 0;
          position: relative;
          .stat_values {
            // margin-left: unset;
            display: flex;
            flex-grow: 1;
            .value1 {
              // margin-right: 93px;
              position: absolute;
              left: 50%;
              margin-left: auto;
            }
            .value2 {
              margin-left: auto;
            }
          }
        }
        .total_stat {
          // padding: 15px;
          // margin-top: 32px;
          // margin-bottom: 32px;
        }
      }
      .simulations_remaining {
        display: flex;
        align-items: center;
        display: none;
        .chart_legend {
          display: flex;
          align-items: center;
          margin-left: 16px;
          .color {
            // width: 16px;
            // height: 16px;
            background-color: rgba(58, 53, 197, 1);
            border-radius: 4px;
            // margin-right: 16px;
          }
          .label4 {
            font-family: Lato;
            // font-size: 16px;
            // font-weight: 400;
            // line-height: 19px;
            color: white;
          }
        }
      }
      .circular_progress_wrapper {
        // width: 80px;
        // height: 80px;
        background-color: rgba(19, 27, 74, 1);
        border-radius: 50%;
        .circular_label_wrapper {
          flex-direction: column;
          .remaining {
            font-family: Inter;
            // font-size: 18px;
            // font-weight: 600;
            // line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
            color: white;
          }
          .progress {
            font-family: Inter;
            // font-size: 12px;
            // font-weight: 600;
            // line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(135, 255, 197, 0.81);
            // margin-top: 10px;
          }
        }
      }
      .buttons {
        position: absolute;
        bottom: 5px;
        width: 98%;
        box-sizing: border-box;
        display: flex;
        gap: 10px;
        .simulate_button {
          width: 50%;
          font-size: 2em;
          // padding: 8px 12px !important;
          background: rgba(57, 52, 195, 0) !important;
          border: 1px solid rgba(57, 52, 195, 1) !important;
          // width: 192px;
          &:first-child {
            // margin-right: 96px;
          }
          .simulate_button_icon {
            transition: filter 0.2s;
            filter: brightness(0) invert(1);
            // width: 16px;
          }
          &:hover {
            background: rgba(57, 52, 195, 1) !important;
            opacity: 0.6;
            .user_button_icon {
              filter: brightness(0) invert(1);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .account_tab_wrapper {
    display: flex !important;
    flex-direction: column !important;
    position: relative;
    padding-bottom: 7em;
    .header_1024 {
      display: flex;
      width: 100%;
      height: fit-content;
      justify-content: space-between;

      .header_labels {
        .title {
          color: white;
          font-size: 3.4em;
        }
        .sims {
          // margin-top: 15px;
          display: flex;
          .sims_remaining {
            color: rgba(39, 110, 192, 1);
            // font-size: 20px;
            // font-weight: 500;
            line-height: normal;
            // margin-right: 17px;
            font-size: 2em;
          }
          .sims_value {
            font-family: Lato;
            // font-size: 20px;
            // font-weight: 400;
            line-height: normal;
            color: rgba(40, 161, 133, 1);
            margin-left: 1em;
            font-size: 2em;
          }
        }
      }

      .header_buttons {
        display: flex;
        .dots_button {
          // margin-left: 40px;
          width: 40px;
          height: 40px;
          background-color: rgba(19, 27, 74, 1);
          border-radius: 50%;
          .dots_icon {
            color: white;
          }
        }
      }
    }
    .securities {
      order: 2;
      background: none;
      padding: 0;
      .header {
        display: none;
      }
      .body {
        width: 100%;
        // display: flex;
        // min-height: 445px;
        flex: 1;
        height: 40vh;

        .table1 {
          // width: 291px;
        }
        .chart {
          // padding: 54px;
        }
      }
    }

    .rankings_wrapper {
      background: none;
      // margin-left: 16px;
      text-align: center;

      padding: 0;
      .transactions {
        display: flex;
        // border-radius: 16px;
      }

      .header {
        display: none;
      }
      .security_label {
        // display: flex;
        font-family: Lato;
        color: rgba(188, 193, 222, 1);
        // font-size: 14px;
        // font-weight: 400;
        // line-height: 17px;
        .security_number {
          font-weight: 700;
        }
      }

      .stats {
        .pie1 {
          // width: 25px;
          // margin-right: 14px;
          img {
            width: 100%;
          }
        }
        min-width: unset;
        // max-width: 400px;
        margin: 0;
        .stat {
          // padding-left: 0;
          // padding-right: 0;
          .stat_values {
            // margin-left: unset;
            display: flex;
            flex-grow: 1;
            .value1 {
              // margin-right: 93px;
              position: absolute;
              left: 50%;
              margin-left: auto;
            }
            .value2 {
              margin-left: auto;
            }
          }
        }
        .total_stat {
          // padding: 15px;
          // margin-top: 32px;
          // margin-bottom: 32px;
          padding: 0.7em 1.5em;
        }
      }
      .simulations_remaining {
        display: flex;
        align-items: center;
        display: none;
        .chart_legend {
          display: flex;
          align-items: center;
          margin-left: 16px;
          .color {
            // width: 16px;
            // height: 16px;
            background-color: rgba(58, 53, 197, 1);
            border-radius: 4px;
            // margin-right: 16px;
          }
          .label4 {
            font-family: Lato;
            // font-size: 16px;
            // font-weight: 400;
            // line-height: 19px;
            color: white;
          }
        }
      }
      .circular_progress_wrapper {
        // width: 80px;
        // height: 80px;
        background-color: rgba(19, 27, 74, 1);
        border-radius: 50%;
        .circular_label_wrapper {
          flex-direction: column;
          .remaining {
            font-family: Inter;
            // font-size: 18px;
            // font-weight: 600;
            // line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
            color: white;
          }
          .progress {
            font-family: Inter;
            // font-size: 12px;
            // font-weight: 600;
            // line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            color: rgba(135, 255, 197, 0.81);
            // margin-top: 10px;
          }
        }
      }
      .buttons {
        position: absolute;
        bottom: 5px;
        width: 98%;
        box-sizing: border-box;
        display: flex;
        gap: 10px;
        .simulate_button {
          width: 50%;
          font-size: 2em;

          // padding: 8px 12px !important;
          // background: rgba(57, 52, 195, 1) !important;
          border: 1px solid rgba(57, 52, 195, 1) !important;
          // width: 192px;
          &:first-child {
            // margin-right: 96px;
          }
          .simulate_button_icon {
            transition: filter 0.2s;
            filter: brightness(0) invert(1);
            // width: 16px;
          }
          &:hover {
            // background: rgba(57, 52, 195, 0) !important;
            opacity: 0.6;
            .user_button_icon {
              filter: brightness(0) invert(1);
            }
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 1600px) {
//   .account_tab_wrapper {
//     // display: flex;
//     flex-wrap: nowrap;
//     width: 100%;
//     .securities {
//       display: flex;
//       border-radius: 16px;
//       background: linear-gradient(180deg, #090c2b 0%, #0e1240 100%);
//       padding: 32px;
//       flex-direction: column;
//       .body {
//         width: 100%;
//         // display: flex;
//         flex: 1;
//         .table1 {
//           width: 291px;
//         }
//         .chart {
//           padding: 54px;
//         }
//       }
//     }

//     .rankings_wrapper {
//       background: linear-gradient(180deg, #090c2b 0%, #0e1240 100%);
//       // margin-left: 16px;
//       text-align: center;

//       padding: 32px 12px 24px 12px;
//       .transactions {
//         display: flex;
//         border-radius: 16px;
//       }

//       .header {
//         justify-content: center;
//         flex-direction: column;
//       }
//       .security_label {
//         // display: flex;
//         font-family: Lato;
//         color: rgba(188, 193, 222, 1);
//         font-size: 14px;
//         font-weight: 400;
//         line-height: 17px;
//         .security_number {
//           font-weight: 700;
//         }
//       }

//       .stats {
//         .pie1 {
//           width: 52px;
//           margin-right: 16.2px;
//         }
//         color: white;
//         display: flex;
//         flex-direction: column;
//         font-family: Lato;
//         font-size: 16px;
//         font-weight: 500;
//         line-height: 19px;
//         .stat {
//           display: flex;
//           margin-bottom: 8px;
//           align-items: center;
//           padding: 0px 25px;
//           .stat_values {
//             margin-left: auto;
//           }
//         }
//         .total_stat {
//           border-radius: 8px;
//           background-color: rgba(19, 27, 74, 1);
//           padding: 15px 25px;
//         }
//       }
//       .simulations_remaining {
//         display: flex;
//         align-items: center;
//         .chart_legend {
//           display: flex;
//           align-items: center;
//           margin-left: 16px;
//           .color {
//             width: 16px;
//             height: 16px;
//             background-color: rgba(58, 53, 197, 1);
//             border-radius: 4px;
//             margin-right: 16px;
//           }
//           .label4 {
//             font-family: Lato;
//             font-size: 16px;
//             font-weight: 400;
//             line-height: 19px;
//             color: white;
//           }
//         }
//       }
//       .circular_progress_wrapper {
//         width: 80px;
//         height: 80px;
//         background-color: rgba(19, 27, 74, 1);
//         border-radius: 50%;
//         .circular_label_wrapper {
//           flex-direction: column;
//           .remaining {
//             font-family: Inter;
//             font-size: 18px;
//             font-weight: 600;
//             line-height: 22px;
//             letter-spacing: 0em;
//             text-align: center;
//             color: white;
//           }
//           .progress {
//             font-family: Inter;
//             font-size: 12px;
//             font-weight: 600;
//             line-height: 15px;
//             letter-spacing: 0em;
//             text-align: center;
//             color: rgba(135, 255, 197, 0.81);
//             margin-top: 10px;
//           }
//         }
//       }
//       .buttons {
//         display: flex;
//         justify-content: space-between;
//         margin-top: 32px;
//         flex-direction: column;
//         max-width: 205px;
//         margin-left: auto;
//         margin-right: auto;
//         .simulate_button {
//           padding: 8px 12px !important;
//           background: rgba(57, 52, 195, 1) !important;
//           border: 1px solid rgba(57, 52, 195, 1) !important;
//           text-transform: capitalize;
//           border-radius: 8px;
//           font-family: Lato;
//           font-size: 18px;
//           font-weight: 500;
//           line-height: 22px;
//           margin-bottom: 5px;

//           transition:
//             background 0.2s,
//             opacity 0.2s;
//           .simulate_button_icon {
//             transition: filter 0.2s;
//             filter: brightness(0) invert(1);
//             width: 16px;
//           }
//           &:hover {
//             background: rgba(57, 52, 195, 0) !important;
//             opacity: 0.6;
//             .user_button_icon {
//               filter: brightness(0) invert(1);
//             }
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1200px) {
//   .account_tab_wrapper {
//     display: flex !important;
//     flex-direction: column !important;
//     .header_1024 {
//       display: flex;
//       width: 100%;
//       height: fit-content;
//       justify-content: space-between;

//       .header_labels {
//         .title {
//           color: white;
//           font-size: 48px;
//           font-weight: 500;
//           line-height: 58px;
//         }
//         .sims {
//           margin-top: 15px;
//           display: flex;
//           .sims_remaining {
//             color: rgba(39, 110, 192, 1);
//             font-size: 20px;
//             font-weight: 500;
//             line-height: 24px;
//             margin-right: 17px;
//           }
//           .sims_value {
//             font-family: Lato;
//             font-size: 20px;
//             font-weight: 400;
//             line-height: 24px;
//             color: rgba(40, 161, 133, 1);
//           }
//         }
//       }

//       .header_buttons {
//         display: flex;
//         .dots_button {
//           margin-left: 40px;
//           width: 40px;
//           height: 40px;
//           background-color: rgba(19, 27, 74, 1);
//           border-radius: 50%;
//           .dots_icon {
//             color: white;
//           }
//         }
//       }
//     }
//     .securities {
//       order: 2;
//       background: none;
//       padding: 0;
//       .header {
//         display: none;
//       }
//       .body {
//         width: 100%;
//         // display: flex;
//         min-height: 445px;
//         flex: 1;
//         .table1 {
//           width: 291px;
//         }
//         .chart {
//           padding: 54px;
//         }
//       }
//     }

//     .rankings_wrapper {
//       background: none;
//       // margin-left: 16px;
//       text-align: center;

//       padding: 0;
//       .transactions {
//         display: flex;
//         border-radius: 16px;
//       }

//       .header {
//         display: none;
//       }
//       .security_label {
//         // display: flex;
//         font-family: Lato;
//         color: rgba(188, 193, 222, 1);
//         font-size: 14px;
//         font-weight: 400;
//         line-height: 17px;
//         .security_number {
//           font-weight: 700;
//         }
//       }

//       .stats {
//         .pie1 {
//           width: 25px;
//           margin-right: 14px;
//           img {
//             width: 100%;
//           }
//         }
//         max-width: 400px;
//         margin-left: auto;
//         margin-right: auto;
//         .stat {
//           padding: 0px 25px;
//           font-family: Lato;
//           font-size: 20px;
//           font-weight: 500;
//           line-height: 24px;
//           color: rgba(121, 128, 169, 1);
//           .stat_values {
//             // margin-left: unset;
//             display: flex;
//             // flex-grow: 1;
//             .value1 {
//               margin-right: 93px;
//             }
//             .value2 {
//               // margin-left: auto;
//             }
//           }
//         }
//         .total_stat {
//           padding: 15px;
//           margin-top: 32px;
//           margin-bottom: 32px;
//         }
//       }
//       .simulations_remaining {
//         display: flex;
//         align-items: center;
//         display: none;
//         .chart_legend {
//           display: flex;
//           align-items: center;
//           margin-left: 16px;
//           .color {
//             width: 16px;
//             height: 16px;
//             background-color: rgba(58, 53, 197, 1);
//             border-radius: 4px;
//             margin-right: 16px;
//           }
//           .label4 {
//             font-family: Lato;
//             font-size: 16px;
//             font-weight: 400;
//             line-height: 19px;
//             color: white;
//           }
//         }
//       }
//       .circular_progress_wrapper {
//         width: 80px;
//         height: 80px;
//         background-color: rgba(19, 27, 74, 1);
//         border-radius: 50%;
//         .circular_label_wrapper {
//           flex-direction: column;
//           .remaining {
//             font-family: Inter;
//             font-size: 18px;
//             font-weight: 600;
//             line-height: 22px;
//             letter-spacing: 0em;
//             text-align: center;
//             color: white;
//           }
//           .progress {
//             font-family: Inter;
//             font-size: 12px;
//             font-weight: 600;
//             line-height: 15px;
//             letter-spacing: 0em;
//             text-align: center;
//             color: rgba(135, 255, 197, 0.81);
//             margin-top: 10px;
//           }
//         }
//       }
//       .buttons {
//         display: flex;
//         justify-content: unset;
//         flex-direction: row;
//         max-width: unset;
//         margin: 0;
//         justify-content: center;
//         .simulate_button {
//           padding: 8px 12px !important;
//           background: rgba(57, 52, 195, 1) !important;
//           border: 1px solid rgba(57, 52, 195, 1) !important;
//           width: 192px;
//           &:first-child {
//             margin-right: 96px;
//           }
//           .simulate_button_icon {
//             transition: filter 0.2s;
//             filter: brightness(0) invert(1);
//             width: 16px;
//           }
//           &:hover {
//             background: rgba(57, 52, 195, 0) !important;
//             opacity: 0.6;
//             .user_button_icon {
//               filter: brightness(0) invert(1);
//             }
//           }
//         }
//       }
//     }
//   }
// }
