.drawer_body {
  padding: 2em;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between !important;
  overflow: hidden;

  .drawer_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .user_card {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 1em;

      .user_photo_container {
        // width: fit-content;
        // background-color: rgba(53, 67, 119, 1);
        // padding: 0px;
        // border-radius: 50%;
        // border: 1px solid rgba(56, 108, 191, 1);
        margin-bottom: 0.3em;

        img {
          border-radius: 50%;
          width: 5.6em;
          height: 5.6em;
          object-fit: cover;
          background-color: rgb(53, 67, 119);
          padding: 0.1em;
          border-radius: 50%;
          border: 1px solid rgb(56, 108, 191);
        }
      }

      .user_button {
        text-transform: capitalize;
        font-family: 'Lato', serif;
        font-size: 1.2em;
        font-weight: 600;
      }
    }

    .stats_wrapper {
      display: flex;
      justify-content: space-around;
      gap: 1.5em;
      width: 100%;

      .stat_card {
        background-color: rgba(19, 27, 74, 1);
        padding: 0.6em;
        display: flex;
        width: fit-content;
        border-radius: 8px;

        img {
          margin-top: auto !important;
          margin-bottom: auto;
          width: 1.3em;
        }

        .labels {
          display: flex;
          flex-direction: column;
          margin-left: 1em;

          .label1 {
            color: white;
            font-size: 0.9em;
            line-height: 1em;
            font-family: 'Lato';
            font-weight: 600;
            margin-bottom: 0.5em;
          }

          .label2 {
            color: rgba(39, 110, 192, 1);
            font-size: 0.8em;
            line-height: 1em;
            font-family: 'Lato';
            font-weight: 500;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .drawer_content {
    margin: 0;
    width: 100%;
    max-height: calc(100vh - 24em);
    overflow-y: auto;

    .menu {
      width: 100%;
      padding: 0;
      .menu_item {
        margin: 0.2em 0;
        padding-top: 0.2em;
        padding-bottom: 0.2em;

        &.Mui-selected {
          display: none !important;
        }

        .menu_item_icon {
          margin-right: 0.8em;
          min-width: 3em;

          .icon_box {
            width: 1.5em;
            background-color: rgba(19, 27, 74, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: background-color 0.1s;
            padding: 0.3em;

            img {
              width: 100%;
            }
          }
        }

        .menu_item_text {
          font-family: 'Lato';
          font-weight: 500;
          font-size: 1em !important;
          color: white;
        }
      }
    }
  }

  .drawer_footer {
    margin-top: 0.5em;
    flex-direction: row;
    display: flex;
    align-items: center;

    img {
      width: 3em;
    }

    .typography_wrapper {
      margin-left: 10px;

      .company_name {
        line-height: normal;
        font-size: 1.2em;
        font-family: 'EB Garamond', serif;
        font-weight: 700;
        color: white;
      }

      .corporation {
        font-weight: 700;
        line-height: 16.9px;
        font-size: 0.6em;
        font-family: 'EB Garamond', serif;
        letter-spacing: 0.2em;
        color: white;
      }
    }
  }
}

@media (orientation: portrait) {
  .drawer_body {
    padding: 4em 1em;

    .drawer_header {
      width: unset;
      margin: 1em;

      .user_card {
        margin-bottom: 5em;

        .user_photo_container {
          img {
            width: 6em;
          }
        }

        .user_button {
          text-transform: capitalize;
          font-family: 'Lato', serif;
          font-size: 1.2em;
          font-weight: 600;
        }
      }

      .stats_wrapper {
        flex-direction: column;
        align-items: unset;

        .stat_card {
          width: unset;
          padding: 1em;

          &:first-child {
            margin-bottom: 0.6em;
          }

          img {
            margin-bottom: auto;
          }

          .labels {
            display: flex;
            flex-direction: column;

            // margin-left: 16px;
            .label1 {
              color: white;
              margin-bottom: 0.8em;
            }

            .label2 {
              color: rgba(39, 110, 192, 1);
              // font-size: 14px;
              // line-height: 16.8px;
              // font-family: 'Lato';
              // font-weight: 500;
              // text-transform: uppercase;
            }
          }
        }
      }
    }

    .drawer_content {
      .menu {
        width: 100%;

        .menu_item {
          margin-top: 1.5em;
          margin-bottom: 1.5em;
          padding-top: 0.7em;
          padding-bottom: 0.7em;

          &.Mui-selected {
            display: none !important;
          }

          .menu_item_icon {
            min-width: unset;
            margin-right: 1.2em;

            .icon_box {
              width: 1.5em;
              background-color: rgba(19, 27, 74, 1);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 16px;
              transition: background-color 0.1s;
              padding: 0.3em;
            }
          }

          .menu_item_text {
            font-family: 'Lato';
            font-weight: 500;
            font-size: 1em !important;
            color: white;
          }
        }
      }
    }

    .drawer_footer {
      img {
        width: 7em;
      }

      .typography_wrapper {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .drawer_body {
    padding: 4em 7em;

    .drawer_header {
      width: unset;
      margin: 1em;

      .user_card {
        margin-bottom: 5em;

        .user_photo_container {
          img {
            width: 11em;
          }
        }

        .user_button {
          font-size: 2.5em;
        }
      }

      .stats_wrapper {
        flex-direction: row;
        align-items: unset;

        .stat_card {
          width: unset;
          padding: 2em 2.5em;
          border-radius: 1.6em;

          &:first-child {
            margin-bottom: 0;
            margin-right: 1.5em;
          }

          img {
            width: 2em;
          }

          .labels {
            display: flex;
            flex-direction: column;
            margin-left: 2em;

            .label1 {
              color: white;
              margin-bottom: 4px;

              font-size: 2em;
            }

            .label2 {
              color: rgba(39, 110, 192, 1);
              font-size: 2em;
              // font-size: 14px;
              // line-height: 16.8px;
              // font-family: 'Lato';
              // font-weight: 500;
              // text-transform: uppercase;
            }
          }
        }
      }
    }

    .drawer_content {
      .menu {
        width: 100%;

        .menu_item {
          margin-top: 1em;
          margin-bottom: 1em;
          padding-top: 0.8em;
          padding-bottom: 0.8em;

          &.Mui-selected {
            display: none !important;
          }

          .menu_item_icon {
            min-width: unset;
            margin-right: 3.2em;

            .icon_box {
              width: 2.5em;
              padding: 1.3em;

              img {
                width: 100%;
              }
            }
          }

          .menu_item_text {
            font-family: 'Lato';
            font-weight: 500;
            font-size: 1.7em !important;
            color: white;
          }
        }
      }
    }

    .drawer_footer {
      display: none;

      img {
        width: 7em;
      }

      .typography_wrapper {
        display: none;
      }
    }
  }
}

// @media screen and (max-width: 1600px) {
//   .drawer_body {
//     padding: 65px 10px 0px 20px;
//     .drawer_header {
//       .user_card {
//         margin-bottom: 24px;
//         .user_photo_container {
//           img {
//           }
//         }
//         .user_button {
//           font-family: 'Lato', serif;
//           font-size: 20px;
//           font-weight: 600;
//         }
//       }

//       .stats_wrapper {
//         flex-direction: column;
//         align-items: center;
//         .stat_card {
//           width: 143px;
//           img {
//             margin-bottom: auto;
//           }

//           .labels {
//             display: flex;
//             flex-direction: column;
//             margin-left: 24px;
//             .label1 {
//               color: white;
//               font-size: 16px;
//               line-height: 19.2px;
//               font-family: 'Lato';
//               font-weight: 600;
//               margin-bottom: 8px;
//             }
//             .label2 {
//               color: rgba(39, 110, 192, 1);
//               font-size: 14px;
//               line-height: 16.8px;
//               font-family: 'Lato';
//               font-weight: 500;
//               text-transform: uppercase;
//             }
//           }
//         }
//       }
//     }
//     .drawer_content {
//       margin-top: 48px;
//       width: 100%;
//       .menu {
//         width: 100%;
//         .menu_item {
//           padding: 4px 18px;

//           &.Mui-selected {
//             display: none !important;
//           }
//           .menu_item_icon {
//             margin-right: 15px;
//             .icon_box {
//               width: 48px;
//               height: 48px;
//               background-color: rgba(19, 27, 74, 1);
//               display: flex;
//               align-items: center;
//               justify-content: center;
//               border-radius: 16px;
//               transition: background-color 0.1s;
//             }
//           }
//           .menu_item_text {
//             font-family: 'Lato';
//             font-weight: 500;
//             font-size: 20px;
//             color: white;
//           }
//         }
//       }
//     }
//     .drawer_footer {
//       margin-top: auto;
//       flex-direction: row;
//       display: flex;
//       align-items: center;
//       img {
//         width: 40px;
//         height: 40px;
//       }
//       .typography_wrapper {
//         margin-left: 10px;
//         .company_name {
//           font-size: 15px;
//           line-height: 19px;
//         }
//         .corporation {
//           line-height: 11.7px;
//           font-size: 9px;
//           letter-spacing: 2px;
//         }
//       }
//     }
//   }
// }

// @media screen and (max-width: 1200px) {
//   .drawer_body {
//     padding: 58px 16px 52px 16px;
//     background: rgba(14, 18, 62, 1);
//     .drawer_header {
//       .user_card {
//         margin-bottom: 64px;
//         .user_photo_container {
//           img {
//           }
//         }
//         .user_button {
//           font-family: 'Lato', serif;
//           font-size: 20px;
//           font-weight: 600;
//         }
//       }

//       .stats_wrapper {
//         flex-direction: column;
//         align-items: center;
//         .stat_card {
//           width: 143px;
//           img {
//             margin-bottom: auto;
//           }

//           .labels {
//             display: flex;
//             flex-direction: column;
//             margin-left: 16px;
//             .label1 {
//               color: white;
//               font-size: 16px;
//               line-height: 19.2px;
//               font-family: 'Lato';
//               font-weight: 600;
//               margin-bottom: 8px;
//             }
//             .label2 {
//               color: rgba(39, 110, 192, 1);
//               font-size: 14px;
//               line-height: 16.8px;
//               font-family: 'Lato';
//               font-weight: 500;
//               text-transform: uppercase;
//             }
//           }
//         }
//       }
//     }
//     .drawer_content {
//       margin-top: 48px;
//       width: 100%;
//       .menu {
//         width: 100%;
//         .menu_item {
//           padding: 4px 18px;

//           &.Mui-selected {
//             display: none !important;
//           }
//           .menu_item_icon {
//             margin-right: 15px;
//             .icon_box {
//               width: 48px;
//               height: 48px;
//               background-color: rgba(19, 27, 74, 1);
//               display: flex;
//               align-items: center;
//               justify-content: center;
//               border-radius: 16px;
//               transition: background-color 0.1s;
//             }
//           }
//           .menu_item_text {
//             font-family: 'Lato';
//             font-weight: 500;
//             font-size: 20px;
//             color: white;
//           }
//         }
//       }
//     }
//     .drawer_footer {
//       margin-top: auto;
//       flex-direction: row;
//       display: flex;
//       align-items: center;
//       img {
//         width: 100px;
//         height: 100px;
//       }
//       .typography_wrapper {
//         display: none;
//         .company_name {
//           font-size: 15px;
//           line-height: 19px;
//         }
//         .corporation {
//           line-height: 11.7px;
//           font-size: 9px;
//           letter-spacing: 2px;
//         }
//       }
//     }
//   }
// }
